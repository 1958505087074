import * as React from "react"
import Layout from "../components/layout"
import { withAuthenticator } from 'aws-amplify-react'
import '@aws-amplify/ui/dist/style.css'

const Admin = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <h1> Admin Page</h1>
    </Layout>
  )
}

// export文を以下に変更
export default withAuthenticator(Admin)
